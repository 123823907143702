import {Howl} from 'howler';
const sounds = {
    move: new Howl({
        src: ['/assets/sounds/Move.ogg'],
    }),
    notify: new Howl({
        src: ['/assets/sounds/Notify.ogg'],
    }),
    capture: new Howl({
        src: ['/assets/sounds/Capture.ogg'],
    }),
}

export const playSound = (key) => sounds[key].play();
