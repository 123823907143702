<script>
  import { onMount, afterUpdate, createEventDispatcher } from "svelte";
  import Chessground from "./cg/index.js";
  import PromotionModal from "./PromotionModal.svelte";
  import { SQUARES } from "chess.js";
  import chess from "../chess.js";

  export let fen,
    viewOnly = false,
    orientation = "white",
    showPromotionModal = false,
    transparent = false,
    moveArrow = null;

  let board, boardElement, premove;

  $: gameOver = fen && chess.game_over();
  $: if (gameOver) {
    premove = null;
  }

  $: if (viewOnly) {
    board?.cancelMove();
  }

  const dispatch = createEventDispatcher();

  const getValidMoves = () => {
    const dests = {};
    if (!viewOnly) {
      SQUARES.forEach((s) => {
        const ms = chess.moves({ square: s, verbose: true });
        if (ms.length) dests[s] = ms.map((m) => m.to);
      });
    }
    return new Map(Object.entries(dests));
  };

  const getLastMoveSquares = () => {
    const history = chess.history({ verbose: true });
    if (!history.length) {
      return [];
    }

    const { from, to } = history[history.length - 1];
    return [from, to];
  };

  const getCheckedColor = () => {
    if (chess.in_check()) {
      return chess.turn() === "b" ? "black" : "white";
    } else {
      return false;
    }
  };

  afterUpdate(() => {
    if (showPromotionModal) return;
    const validMoves = getValidMoves();
    board.set({
      viewOnly,
      fen: chess.fen(),
      orientation,
      turnColor: chess.turn() === "w" ? "white" : "black",
      check: getCheckedColor(),
      movable: {
        dests: validMoves,
        color: orientation,
      },
      lastMove: getLastMoveSquares(),
      // drawable: {
      //   shapes: [
      //     {
      //       orig: "e7",
      //       dest: "b2",
      //       brush: "blue",
      //       // brush?: string;
      //       // modifiers?: DrawModifiers;
      //       // piece?: DrawShapePiece;
      //       // customSvg?: string;
      //     },
      //   ],
      // },
    });
    board.setShapes(
      moveArrow
        ? [
            {
              ...moveArrow,
              brush: "yellow",
            },
          ]
        : []
    );

    if (premove) {
      const { from, to } = premove;
      premove = null;
      if (validMoves.get(from)?.includes(to)) {
        dispatch("boardMove", { from, to });
      }
    }
  });

  onMount(() => {
    board = Chessground(boardElement, {
      fen,
      orientation,
      movable: {
        free: false,
        dests: getValidMoves(),
        events: {
          after: (from, to) => {
            dispatch("boardMove", { from, to });
          },
        },
      },
      premovable: {
        events: {
          set: (orig, dest, metadata) => {
            premove = { from: orig, to: dest };
          },
          unset: () => {
            premove = null;
          },
        },
      },
      highlight: {
        lastMove: true,
        check: true,
      },
      drawable: {
        eraseOnClick: false,
        // brushes: {
        // "paleBlue"
        //         export interface DrawBrush {
        //           key: string;
        //           color: string;
        //           opacity: number;
        //           lineWidth: number;
        //         }
        //         export interface DrawBrushes {
        //           [name: string]: DrawBrush;
        // }
        // },
      },
      predroppable: {
        enabled: true,
      },
    });
  });
</script>

<div bind:this={boardElement} class="board merida" class:transparent />

{#if showPromotionModal}
  <PromotionModal color={chess.turn()} on:promote />
{/if}

<style>
  .board {
    width: 100vw;
    height: 100vw;
    max-height: 480px;
    max-width: 480px;
    position: relative;
  }
  .transparent {
    opacity: 0.3;
  }
</style>
