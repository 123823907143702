import App from "./App.svelte";
import "carbon-components-svelte/css/g100.css";
import "./theme.css";
import "./chessground.css";

import * as Sentry from "@sentry/svelte";
import { BrowserTracing } from "@sentry/tracing";

// Initialize the Sentry SDK here
Sentry.init({
  dsn: "https://41ff40fa17584c679b5d702559970290@o4503958199271424.ingest.sentry.io/4503958201237504",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: import.meta.env.MODE,
});

const app = new App({
  target: document.getElementById("app"),
});

export default app;
