import { derived, get, writable } from "svelte/store";
import { getRandomAlias } from "./utils/id.js";
import { Timestamp } from "firebase/firestore";
import { getUserByUID, updateGame } from "./firebase.js";
import chess from "./chess.js";
import { playSound } from "./utils/sounds.js";

export const time = writable(300);
export const name = writable("");
export const alias = writable(getRandomAlias());
export const UID = writable("");
export const user = writable(null);
export const isPrivate = writable(false);
export const game = writable({});
export const canAbortGame = writable(true);
export const canResignGame = writable(false);
export const canLeaveGame = writable(false);
export const canUndoMove = writable(false);
export const canAskForRematch = writable(false);
export const opponent = writable(null);
export const isMyTurn = writable(false);

export const getTime = () => get(time);
export const getName = () => {
  const nameVal = get(name);
  return !nameVal.trim() ? get(alias) : nameVal;
};
export const getUID = () => get(UID);
export const getGame = () => get(game);
export const getEngineRemaining = () => getGame()?.engineRemaining;
export const getGameDuration = () => (getGame()?.time || 0) * 1000;
export const lobby = writable([]);
export const loadingLobby = writable(true);
export const loadingUserOrLobby = derived(
  [loadingLobby, user],
  ([loadingLobby, user]) => !user || loadingLobby
);
export const hasJoinedLobby = derived(
  [lobby, UID],
  ([lobby, uid]) => uid && lobby.some(({ id }) => id === uid)
);

export const timeRemaining = writable({
  white: 0,
  black: 0,
});

export const message = writable(null);
let timeInterval;
export function stopClocks() {
  timeInterval && clearInterval(timeInterval);
}
export function resetClocks() {
  stopClocks();
  const duration = getGameDuration();
  timeRemaining.set({
    white: duration,
    black: duration,
  });
}

function getConsumed(mt = []) {
  const initial = {
    white: 0,
    black: 0,
  };
  if (mt.length <= 2) return initial;
  return mt.slice(2).reduce((prev, curr, i) => {
    const color = i % 2 === 0 ? "white" : "black";
    const consumedByColor = prev[color] + (curr - mt[i + 1]);
    return {
      ...prev,
      [color]: consumedByColor - (i > 1 ? 100 : 0),
    };
  }, initial);
}

function updateTimeRemaining({
  duration,
  moveTimes,
  isPlayingWhite,
  id,
  status,
}) {
  const consumed = getConsumed(moveTimes);
  const lastMoveTime = moveTimes.slice(-1)[0];
  const elapsed =
    status === "FINISHED" || moveTimes.length < 2
      ? 0
      : new Date().getTime() - lastMoveTime;
  const isWhite = moveTimes.length % 2 === 0;
  const white = duration - consumed.white - (isWhite ? elapsed : 0);
  const black = duration - consumed.black - (!isWhite ? elapsed : 0);
  timeRemaining.set({ white: Math.max(white, 0), black: Math.max(black, 0) });
  if (
    (isPlayingWhite && white <= 0) ||
    (isPlayingWhite && black < -2000) ||
    (!isPlayingWhite && black <= 0) ||
    (!isPlayingWhite && white < -2000)
  ) {
    stopClocks();
    if (status !== "FINISHED") {
      updateGame(id, {
        status: "FINISHED",
        termination: "TIME_FORFEIT",
        winner: white > 0 ? "white" : "black",
      });
    }
  }
}

function updateClocks({
  status,
  moveTS,
  time,
  whiteUID,
  id,
  termination,
  winner,
}) {
  const moveTimes = Object.values(moveTS || {});
  if (!status || status === "READY" || moveTimes.length < 2) {
    resetClocks();
    return;
  }
  if (chess.game_over() || status === "FINISHED") {
    stopClocks();
    if (termination === "TIME_FORFEIT") {
      timeRemaining.update((prev) => ({
        white: winner === "white" ? prev.white : 0,
        black: winner === "black" ? prev.black : 0,
      }));
    }
    return;
  }
  const milliValues = moveTimes.map(({ seconds, nanoseconds }) =>
    new Timestamp(seconds, nanoseconds).toMillis()
  );
  if (milliValues.length < 2) return;
  const isPlayingWhite = whiteUID === getUID();
  const duration = time * 1000;
  timeInterval && clearInterval(timeInterval);
  timeInterval = setInterval(() => {
    updateTimeRemaining({
      duration,
      moveTimes: milliValues,
      isPlayingWhite,
      id,
      status,
    });
  }, 100);
}

function updateActions({ moveTS, status }) {
  const moveCount = Object.keys(moveTS || {}).length;
  const clockHasStarted = moveCount >= 2;
  const canAbort =
    (!clockHasStarted && status === "ONGOING") || status === "READY";
  canAbortGame.set(canAbort);
  canResignGame.set(clockHasStarted && status === "ONGOING");
  canLeaveGame.set(status === "FINISHED");
}

function updateOpponent({ whiteUID, blackUID }) {
  const uid = getUID();
  const opponentUID = uid && uid === whiteUID ? blackUID : whiteUID;
  if (opponentUID && get(opponent)?.uid !== opponentUID) {
    getUserByUID(opponentUID).then(opponent.set);
  }
}

opponent.subscribe(console.log);

function getSoundKeyByMove({ status }) {
  if (chess.game_over() || ["READY", "FINISHED"].includes(status))
    return "notify";
  const history = chess.history({ verbose: true });
  if (!history.length) return null;
  const move = history.slice(-1)[0];
  if (move?.captured) {
    return "capture";
  }
  return "move";
}
function conditionallyPlaySound(game) {
  if (!game.status) return;
  const soundKey = getSoundKeyByMove(game);
  soundKey && playSound(soundKey);
}

function updateChessState({ pgn, fen }) {
  if (pgn) {
    chess.load_pgn(pgn);
  } else if (fen) {
    chess.load(fen);
  } else {
    chess.reset();
  }
}

function updateMessages({ status, termination }) {
  message.set(null);
  if (status === "FINISHED") {
    message.set(termination);
  }
}

export const userPlaysWhite = writable(true);
export const playColor = derived(userPlaysWhite, ($playsWhite) =>
  $playsWhite ? "white" : "black"
);

function updateUser(game) {
  const UID = getUID();
  const playsWhite = UID && game?.whiteUID === UID;
  userPlaysWhite.set(playsWhite);
  isMyTurn.set(
    playsWhite ? !game.turn || game?.turn === "w" : game?.turn === "b"
  );
}

game.subscribe((game) => {
  updateUser(game);
  updateChessState(game);
  updateMessages(game);
  updateClocks(game);
  conditionallyPlaySound(game);
  updateActions(game);
  updateOpponent(game);
});
