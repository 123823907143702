import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
import {
  getFirestore,
  getDoc,
  getDocs,
  doc,
  addDoc,
  onSnapshot,
  collection,
  setDoc,
  updateDoc,
  deleteDoc,
  documentId,
  query,
  where,
  serverTimestamp,
} from "firebase/firestore";
import {
  game,
  getGame,
  getName,
  getTime,
  getUID,
  loadingLobby,
  lobby,
  user,
} from "./stores";

const firebaseConfig = {
  apiKey: "AIzaSyA1kfu8sb_rSr1xyjAPhXxPBIVSzM5B8fQ",
  authDomain: "lynsjakk.firebaseapp.com",
  databaseURL: "https://lynsjakk.firebaseio.com",
  projectId: "lynsjakk",
  storageBucket: "lynsjakk.appspot.com",
  messagingSenderId: "271669226545",
  appId: "1:271669226545:web:512f106a464b182083b910",
  measurementId: "G-EYB6R4LXK0",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);

export const lobbyUnsub = () => {
  return onSnapshot(collection(db, "lobby"), (lobbySnapshot) => {
    loadingLobby.set(false);
    lobby.set(lobbySnapshot.docs.map((u) => ({ id: u.id, ...u.data() })));
  });
};

export const listenToServerGame = (id) =>
  onSnapshot(doc(db, "games", id), (gameSnapshot) => {
    if (gameSnapshot.metadata.hasPendingWrites) return;
    const data = gameSnapshot.data();
    game.set({
      id,
      ...data,
      ...(!data.engineRemaining && { engineRemaining: { white: 3, black: 3 } }),
    });
  });

export const updateGame = async (id, { moveCount = 0, ...data }) => {
  const extendedData = {
    ...data,
    updatedAt: serverTimestamp(),
    ...(moveCount && {
      moveTS: {
        ...data.moveTS,
        [moveCount]: serverTimestamp(),
      },
    }),
  };
  await updateDoc(doc(db, "games", id), extendedData);
};

export const resignGame = async (id) => {
  const uid = getUID();
  const game = getGame();
  await updateGame(id, {
    status: "FINISHED",
    termination: "CONCEDED",
    terminatedBy: uid,
    winner: game.whiteUID === uid ? "black" : "white",
  });
  await updateDoc(doc(db, "users", uid), { gameID: null });
};
export const abortGame = async (id) => {
  const uid = getUID();
  await updateGame(id, {
    status: "FINISHED",
    termination: "ABORTED",
    terminatedBy: uid,
    winner: null,
  });
  await leaveGame();
};
export const leaveGame = async () => {
  const uid = getUID();
  await updateDoc(doc(db, "users", uid), { gameID: null });
};

export const createUserIfNotExistsAndSubscribeToChanges = async (UID) => {
  const userDocRef = await getDoc(doc(db, "users", UID));
  if (!userDocRef.exists()) {
    await setDoc(doc(db, "users", UID), {});
    user.set({});
  } else {
    user.set(userDocRef.data());
  }
  return onSnapshot(doc(db, "users", UID), (userSnapshot) => {
    user.set(userSnapshot.data());
  });
};

export const getUserByUID = async (uid) => {
  const snap = await getDoc(doc(db, "users", uid));
  return { uid, ...snap.data() };
};

export const joinLobby = async () => {
  const uid = getUID();
  const username = getName();
  setDoc(doc(db, "users", uid), {
    username,
  });
  return await setDoc(doc(db, "lobby", uid), {
    time: getTime(),
    username,
  });
};
export const joinGame = async ({ id, time }) => {
  const UID = getUID();
  const username = getName();
  const playerIDs = [id, UID];
  const [whiteUID, blackUID] =
    Math.random() < 0.5 ? playerIDs : playerIDs.reverse();
  addDoc(collection(db, "games"), {
    whiteUID,
    blackUID,
    time,
    increment: time === 300 ? 3 : 2,
    fen: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
    status: "READY",
  }).then(({ id }) => {
    const q = query(
      collection(db, "users"),
      where(documentId(), "in", [whiteUID, blackUID])
    );

    getDocs(q).then((sn) => {
      sn.docs.forEach((docSnap) => {
        updateDoc(doc(db, "users", docSnap.id), {
          gameID: id,
          ...(docSnap.id === UID && { username }),
        });
        deleteDoc(doc(db, "lobby", docSnap.id));
      });
    });
  });
};

export const leaveLobby = async () => {
  return await deleteDoc(doc(db, "lobby", getUID()));
};

export const signIn = async () => signInAnonymously(auth);
