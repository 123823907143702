<script>
  import { OverflowMenu, OverflowMenuItem } from "carbon-components-svelte";
  import Menu from "carbon-icons-svelte/lib/Menu.svelte";
  import {
    canAbortGame,
    canLeaveGame,
    canResignGame,
    canUndoMove,
    game,
  } from "../stores.js";
  import chess from "../chess.js";
  import { abortGame, resignGame, leaveGame } from "../firebase.js";

  import { useNavigate } from "svelte-navigator";

  const navigate = useNavigate();

  const onLeaveClicked = async () => {
    chess.reset();
    await abortGame($game.id);
    navigate("/");
    //TODO melding til motstander
  };

  const onLeaveGame = async () => {
    chess.reset();
    await leaveGame($game.id);
    navigate("/");
  };

  const onResign = async () => {
    chess.reset();
    await resignGame($game.id);
    navigate("/");
    //TODO melding til motstander
  };
</script>

<OverflowMenu size="xl" direction="top" icon={Menu}>
  {#if $canUndoMove}
    <OverflowMenuItem text="Angre trekk" />
  {/if}
  {#if $canAbortGame}
    <OverflowMenuItem danger text="Avbryt partiet" on:click={onLeaveClicked} />
  {:else if $canResignGame}
    <OverflowMenuItem danger text="Gi opp" on:click={onResign} />
  {:else if $canLeaveGame}
    <OverflowMenuItem danger text="Gå til lobby" on:click={onLeaveGame} />
  {/if}
</OverflowMenu>
