import {
  unselect,
  cancelMove,
  getKeyAtDomPos,
  getSnappedKeyAtDomPos,
  whitePov,
} from "./board.js";
import { eventPosition, isRightButton } from "./util.js";
const brushes = ["green", "red", "blue", "yellow"];
export function start(state, e) {
  // support one finger touch only
  if (e.touches && e.touches.length > 1) return;
  e.stopPropagation();
  e.preventDefault();
  e.ctrlKey ? unselect(state) : cancelMove(state);
  const pos = eventPosition(e),
    orig = getKeyAtDomPos(pos, whitePov(state), state.dom.bounds());
  if (!orig) return;
  state.drawable.current = {
    orig,
    pos,
    brush: eventBrush(e),
    snapToValidMove: state.drawable.defaultSnapToValidMove,
  };
  processDraw(state);
}
export function processDraw(state) {
  requestAnimationFrame(() => {
    const cur = state.drawable.current;
    if (cur) {
      const keyAtDomPos = getKeyAtDomPos(
        cur.pos,
        whitePov(state),
        state.dom.bounds()
      );
      if (!keyAtDomPos) {
        cur.snapToValidMove = false;
      }
      const mouseSq = cur.snapToValidMove
        ? getSnappedKeyAtDomPos(
            cur.orig,
            cur.pos,
            whitePov(state),
            state.dom.bounds()
          )
        : keyAtDomPos;
      if (mouseSq !== cur.mouseSq) {
        cur.mouseSq = mouseSq;
        cur.dest = mouseSq !== cur.orig ? mouseSq : undefined;
        state.dom.redrawNow();
      }
      processDraw(state);
    }
  });
}
export function move(state, e) {
  if (state.drawable.current) state.drawable.current.pos = eventPosition(e);
}
export function end(state) {
  const cur = state.drawable.current;
  if (cur) {
    if (cur.mouseSq) addShape(state.drawable, cur);
    cancel(state);
  }
}
export function cancel(state) {
  if (state.drawable.current) {
    state.drawable.current = undefined;
    state.dom.redraw();
  }
}
export function clear(state) {
  if (state.drawable.shapes.length) {
    state.drawable.shapes = [];
    state.dom.redraw();
    onChange(state.drawable);
  }
}
function eventBrush(e) {
  var _a;
  const modA = (e.shiftKey || e.ctrlKey) && isRightButton(e);
  const modB =
    e.altKey ||
    e.metaKey ||
    ((_a = e.getModifierState) === null || _a === void 0
      ? void 0
      : _a.call(e, "AltGraph"));
  return brushes[(modA ? 1 : 0) + (modB ? 2 : 0)];
}
function addShape(drawable, cur) {
  const sameShape = (s) => s.orig === cur.orig && s.dest === cur.dest;
  const similar = drawable.shapes.find(sameShape);
  if (similar) drawable.shapes = drawable.shapes.filter((s) => !sameShape(s));
  if (!similar || similar.brush !== cur.brush) drawable.shapes.push(cur);
  onChange(drawable);
}
function onChange(drawable) {
  if (drawable.onChange) drawable.onChange(drawable.shapes);
}
