<script>
  export let color;
  const pieces = ['Q', 'R', 'B', 'N'];
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  function onPromote(piece) {
    dispatch('promote', {piece: piece.toLowerCase()});
  }
</script>

<style>
    .promote-bg {
        position: absolute;
        top: 0;
        z-index: 10;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color:rgba(0, 0, 0, 0.5);
    }
    .promote {
        display: flex;
        justify-content: center;
        position: relative;
        background: white;
        margin: 0 auto;
        padding: 10px;
    }
    button {
        padding: 8px;
        margin: 4px;
    }
    button:hover {
        background: #208530;
        border: 2px solid black;
        cursor: pointer;
    }

</style>

<div
  class="promote-bg"
>
    <div class="promote">
        {#each pieces as piece}
          <button on:click={() => onPromote(piece)}>
            <img class="fill-current w-14 h-14" src={`/assets/pieces/merida/${color}${piece}.svg`} alt="piece"/>
          </button>
        {/each}
    </div>
</div>
