export function explosion(state, keys) {
  state.exploding = { stage: 1, keys };
  state.dom.redraw();
  setTimeout(() => {
    setStage(state, 2);
    setTimeout(() => setStage(state, undefined), 120);
  }, 120);
}
function setStage(state, stage) {
  if (state.exploding) {
    if (stage) state.exploding.stage = stage;
    else state.exploding = undefined;
    state.dom.redraw();
  }
}
