<script>
  import { onMount } from "svelte";
  import {
    createUserIfNotExistsAndSubscribeToChanges,
    signIn,
  } from "./firebase";
  import { UID } from "./stores";
  import { Router, Route } from "svelte-navigator";
  import Lobby from "./components/Lobby.svelte";
  import Game from "./components/Game.svelte";

  let unsub;

  onMount(() => {
    signIn().then(async (authResponse) => {
      const uid = authResponse?.user?.uid;
      UID.set(uid);
      unsub = await createUserIfNotExistsAndSubscribeToChanges(uid);
    });
    return () => {
      unsub && unsub();
    };
  });
</script>

<main>
  <Router>
    <Route path="/">
      <Lobby />
    </Route>
    <Route path="p/:id" let:params primary={false}>
      <Game gameID={params.id} />
    </Route>
  </Router>
</main>

<style>
  main {
    max-width: 800px;
    margin: auto;
  }
</style>
