<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import * as Sentry from "@sentry/svelte";
  import { Button, Popover } from "carbon-components-svelte";
  import MachineLearning from "carbon-icons-svelte/lib/MachineLearning.svelte";
  import chess, {
    getTerminationAndWinner,
    getUCIMoveHistory,
  } from "../chess.js";
  import { listenToServerGame, updateGame } from "../firebase";
  import Board from "./Board.svelte";
  import Admin from "./Admin.svelte";
  import Clock from "./Clock.svelte";
  import GameMenu from "./GameMenu.svelte";
  import {
    canAskForRematch,
    game,
    getGame,
    message,
    opponent,
    stopClocks,
    timeRemaining,
    user,
    getEngineRemaining,
    playColor,
    isMyTurn,
    userPlaysWhite,
  } from "../stores.js";
  import { getTerminationText } from "../utils/texts";

  export let gameID = null;
  let promotionMove, showPromotionModal, engine, engineMove;

  onMount(() => {
    chess.reset();
    game.set({ id: gameID });
    const unsub = listenToServerGame(gameID);
    return () => {
      chess.reset();
      unsub();
    };
  });

  function placeMoveAndSyncState(move) {
    let gameData = {
      status: "ONGOING",
      engineActive: false,
    };
    if (!chess.move(move)) {
      return;
    }
    if (chess.game_over()) {
      gameData = {
        status: "FINISHED",
        ...getTerminationAndWinner(),
      };
      stopClocks();
    }
    updateGame(gameID, {
      ...getGame(),
      ...gameData,
      fen: chess.fen(),
      pgn: chess.pgn(),
      turn: chess.turn(),
      moveCount: chess.history().length,
    });
  }

  function isPromotionMove({ from, to }) {
    return (
      chess
        .moves({ verbose: true })
        .filter(
          (move) =>
            from === move.from && to === move.to && move.flags.includes("p")
        ).length > 0
    );
  }

  function onBoardMove(event) {
    engineMove = null;
    const { from, to } = event.detail;
    if (isPromotionMove({ from, to })) {
      promotionMove = { from, to };
      showPromotionModal = true;
    } else {
      placeMoveAndSyncState({ from, to });
    }
  }

  function onPromotionMove(event) {
    const { piece } = event.detail;
    const { from, to } = promotionMove;
    showPromotionModal = false;
    promotionMove = null;
    placeMoveAndSyncState({ from, to, promotion: piece });
  }

  async function showEngineMove() {
    let updateColor = $userPlaysWhite ? "white" : "black";
    const remaining = getEngineRemaining();
    remaining[updateColor] = remaining[updateColor] - 1;
    updateGame(gameID, {
      engineActive: true,
      engineRemaining: remaining || {
        white: userPlaysWhite ? 2 : 3,
        black: userPlaysWhite ? 3 : 2,
      },
    });

    if (!engine) {
      const wasmSupported =
        typeof WebAssembly === "object" &&
        WebAssembly.validate(
          Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00)
        );

      engine = new Worker(
        wasmSupported ? "/stockfish.wasm.js" : "/stockfish.js"
      );
    }
    engine.onmessageerror = (event) => {
      Sentry.captureException(`Stockfish error: ${event}`);
    };
    engine.onmessage = (event) => {
      const line = event?.data || event;
      if (line.length) {
        let sanMove;
        const pvIndex = line.lastIndexOf("pv");
        if (line.startsWith("bestmove")) {
          sanMove = line.split(" ")[1];
        } else if (pvIndex) {
          sanMove = line.substring(pvIndex + 3, pvIndex + 7);
        }
        engineMove = {
          orig: sanMove.substring(0, 2),
          dest: sanMove.substring(2, 4),
        };
      }
    };
    engine.postMessage("uci");
    engine.postMessage(`position startpos moves ${getUCIMoveHistory()}`);
    engine.postMessage(`go wtime 10000 btime 10000`);
  }
</script>

<div>
  {#if $game.status && $playColor}
    <div class="board-wrapper">
      <div class="status-row">
        <span class="user">{$opponent?.username}</span>
        <Clock
          millis={$playColor === "white"
            ? $timeRemaining.black
            : $timeRemaining.white}
        />
      </div>
      <Board
        fen={$game.fen}
        viewOnly={$game.status === "FINISHED"}
        orientation={$playColor}
        moveArrow={engineMove}
        {showPromotionModal}
        on:boardMove={onBoardMove}
        on:promote={onPromotionMove}
      />
      <div class="status-row">
        <div style="display: flex; position: relative; align-items: center">
          <span class="user">
            {$user?.username}
          </span>
          {#if $canAskForRematch}
            <Button kind="secondary">Omkamp</Button>
          {/if}
          <Popover open={!!$message}>
            <div />
          </Popover>
        </div>

        <Clock
          millis={$playColor === "white"
            ? $timeRemaining.white
            : $timeRemaining.black}
        />
      </div>
      <div class="message-row">
        <div>
          {#if $game.engineRemaining?.[$playColor] && $isMyTurn}
            <div style="position: relative" in:fade>
              <Button
                iconDescription="Tilkall hjelp"
                tooltipAlignment="start"
                tooltipPosition="right"
                class="engine"
                kind="ghost"
                icon={MachineLearning}
                on:click={showEngineMove}
              />

              <span class="help-count"
                >{$game.engineRemaining?.[$playColor]}</span
              >
            </div>
          {/if}
        </div>

        <GameMenu />
        {#if !!$message}
          <span class="status-text">
            {getTerminationText($game)}
          </span>
        {/if}
      </div>
    </div>
  {/if}

  {#if ["localhost", "127.0.0.1"].includes(location.hostname)}
    <Admin {chess} {gameID} />
  {/if}
</div>

<style>
  .board-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    position: relative;
    max-width: 480px;
  }
  .message-row {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    font-size: 1.2rem;
    margin-top: -12px;
    position: relative;
  }
  .help-count {
    position: absolute;
    bottom: -4px;
    right: -4px;
    font-size: 16px;
    font-weight: 600;
    user-select: none;
  }

  .status-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .status-text {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    font-size: 1.2rem;
    z-index: -1;
  }
  :global(.bx--overflow-menu__icon) {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
  :global(.bx--overflow-menu-options) {
    right: 0 !important;
    left: unset !important;
  }
  :global(.bx--overflow-menu-options::after) {
    right: 0 !important;
    left: unset !important;
  }
  :global(.engine) {
    padding: 0.2rem !important;
  }
  :global(.engine svg) {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
  .user {
    font-weight: 500;
    font-size: 1.2rem;
    padding: 0 8px;
  }
</style>
