// append and remove only. No updates.
export function syncShapes(shapes, root, renderShape) {
  const hashesInDom = new Map(), // by hash
    toRemove = [];
  for (const sc of shapes) hashesInDom.set(sc.hash, false);
  let el = root.firstChild,
    elHash;
  while (el) {
    elHash = el.getAttribute("cgHash");
    // found a shape element that's here to stay
    if (hashesInDom.has(elHash)) hashesInDom.set(elHash, true);
    // or remove it
    else toRemove.push(el);
    el = el.nextSibling;
  }
  // remove old shapes
  for (const el of toRemove) root.removeChild(el);
  // insert shapes that are not yet in dom
  for (const sc of shapes) {
    if (!hashesInDom.get(sc.hash)) root.appendChild(renderShape(sc));
  }
}
