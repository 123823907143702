export default {
  CHECKMATE: "Sjakk matt",
};

export function getTerminationText({ termination, winner }) {
  const color = winner && winner === "white" ? "Hvit" : "Sort";
  const loser = winner === "white" ? "Sort" : "Hvit";
  if (winner && termination === "CHECKMATE") {
    return `${color} har vunnet`;
  } else if (termination === "CONCEDED") {
    return `${loser} ga seg`;
  } else if (termination === "TIME_FORFEIT") {
    return `${loser} tapte på tid`;
  } else if (termination === "ABORTED") {
    return "Partiet ble avbrutt";
  }
}
