<script>
  import { abortGame, updateGame } from "../firebase.js";
  import { useNavigate } from "svelte-navigator";
  import { resetClocks } from "../stores.js";

  const navigate = useNavigate();

  export let chess;
  export let gameID;

  function revert() {
    chess.undo();
    updateGame(gameID, {
      fen: chess.fen(),
      pgn: chess.pgn(),
    });
  }
  function restart() {
    chess.reset();
    resetClocks();
    updateGame(gameID, {
      fen: chess.fen(),
      pgn: chess.pgn(),
      turn: "w",
      time: 300,
      status: "READY",
      moveTS: null,
      updatedAt: null,
      winner: null,
      termination: null,
    });
  }

  async function leave() {
    chess.reset();
    await abortGame(gameID);
    navigate("/");
  }
</script>

<div class="admin">
  <button on:click={revert}>Revert</button>
  <button on:click={restart}>Restart</button>
  <button on:click={leave}>Leave</button>
</div>

<style>
  .admin {
    display: flex;
    position: fixed;
    bottom: 0;
    padding: 20px;
  }
</style>
