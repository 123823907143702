<script>
  export let millis = 0;

  $: secs = Math.ceil(millis / 1000);
  $: minutes = Math.trunc(secs / 60);
  $: secsLeftInMinute = Math.trunc(secs % 60);

  $: red = secs <= 10;
</script>

<time class:red>
  {minutes}:{String(secsLeftInMinute).padStart(2, "0")}
</time>

<style lang="scss">
  @use "@carbon/colors";
  time {
    font-family: "IBM Plex Mono", "Menlo", monospace;
    font-weight: bold;
    font-size: 2rem;
    line-height: 42px;
    padding: 0 8px;
    text-align: right;
  }
  .red {
    color: #da1e28;
  }
</style>
