<script>
  import {
    Form,
    FormGroup,
    Modal,
    RadioButton,
    RadioButtonGroup,
    TextInput,
  } from "carbon-components-svelte";

  import { name, time } from "../stores";
  import { createEventDispatcher } from "svelte";
  import { alias, isPrivate } from "../stores.js";
  export let open = false;

  let color = "random";

  const dispatch = createEventDispatcher();
</script>

<Modal
  bind:open
  size="xs"
  modalHeading="Nytt parti"
  primaryButtonText="Opprett"
  secondaryButtons={[{ text: "Avbryt" }]}
  selectorPrimaryFocus="#alias-input"
  on:click:button--secondary
  on:open
  on:close
  on:submit={() => {
    dispatch("submit", {
      color,
    });
  }}
  class="join-lobby-modal"
>
  <Form>
    <FormGroup>
      <TextInput
        size="xl"
        id="alias-input"
        labelText="Brukernavn"
        placeholder={$alias}
        bind:value={$name}
      />
    </FormGroup>
    <div class="submit-row">
      <FormGroup legendText="Tid">
        <RadioButtonGroup name="radio-button-group" bind:selected={$time}>
          <RadioButton id="radio-3" value={600} labelText="10 min" />
          <RadioButton id="radio-2" value={300} labelText="5 min" />
          <RadioButton id="radio-1" value={180} labelText="3 min" />
        </RadioButtonGroup>
      </FormGroup>
      <FormGroup legendText="Jeg spiller som">
        <RadioButtonGroup name="radio-button-group" bind:selected={color}>
          <RadioButton
            id="radio-random"
            value={"random"}
            labelText="Tilfeldig"
          />
          <RadioButton id="radio-white" value={"white"} labelText="Hvit" />
          <RadioButton id="radio-black" value={"black"} labelText="Sort" />
        </RadioButtonGroup>
      </FormGroup>
      <!--      <FormGroup legendText="Hvem kan bli med?">-->
      <!--        <RadioButtonGroup name="radio-button-group" bind:selected={$isPrivate}>-->
      <!--          <RadioButton-->
      <!--                  id="radio-private-false"-->
      <!--                  value={false}-->
      <!--                  labelText="Alle"-->
      <!--          />-->
      <!--          <RadioButton-->
      <!--                  id="radio-private-true"-->
      <!--                  value={true}-->
      <!--                  labelText="Spør meg først"-->
      <!--          />-->
      <!--        </RadioButtonGroup>-->
      <!--      </FormGroup>-->
    </div>
  </Form>
</Modal>

<style>
  :global(.join-lobby-modal) {
    text-align: left;
  }
</style>
