<script>
  import { afterUpdate, onMount } from "svelte";
  import { fade } from "svelte/transition";
  import {
    Button,
    DataTable,
    DataTableSkeleton,
    Toolbar,
    ToolbarContent,
  } from "carbon-components-svelte";
  import JoinLobbyModal from "./JoinLobbyModal.svelte";
  import { joinLobby, leaveLobby, lobbyUnsub } from "../firebase";
  import { hasJoinedLobby, lobby, user } from "../stores";
  import { useNavigate } from "svelte-navigator";
  import { loadingLobby, UID } from "../stores.js";
  import { joinGame } from "../firebase.js";

  const navigate = useNavigate();
  let joinLobbyModalOpen = false;

  function closeJoinLobbyModal() {
    joinLobbyModalOpen = false;
  }

  function onSubmit() {
    closeJoinLobbyModal();
    joinLobby();
  }

  onMount(() => {
    const unsub = lobbyUnsub();
    return () => {
      unsub();
    };
  });

  afterUpdate(() => {
    if ($lobby?.some(({ id }) => id === $UID)) {
      document.querySelector(`[data-row=${$UID}]`)?.classList.add("myRow");
    }
  });

  $: if ($user?.gameID) {
    navigate(`/p/${$user.gameID}`);
  }

  $: isEmpty = !$loadingLobby && !$lobby.length;
</script>

<div class="lobby" class:empty={isEmpty}>
  <div class="lobby-header">
    <div class="title">
      <span>L</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 69.38 122.88"
        style="enable-background:new 0 0 69.38 122.88"
        xml:space="preserve"
        ><path
          d="M21.34.41 65.26 0c.81-.04 1.64.18 2.37.66a3.946 3.946 0 0 1 1.08 5.48L45.77 40.38h19.45v.01a3.95 3.95 0 0 1 3.05 6.46L7 121.44a3.952 3.952 0 0 1-4.51 1.16 3.94 3.94 0 0 1-2.21-5.13L21.32 64.5l-15.77-.15c-.45.03-.9-.01-1.36-.14-2.1-.6-3.32-2.79-2.72-4.89l16.1-56.04h.01A3.944 3.944 0 0 1 21.34.41z"
          style="fill:#383838"
        /><path
          style="fill-rule:evenodd;clip-rule:evenodd;fill:#fcdb33"
          d="m65.43 3.95-44.06.41L5.27 60.4l21.84.21-23.16 58.32 61.27-74.59H38.35L65.43 3.95z"
        /><path
          style="fill-rule:evenodd;clip-rule:evenodd;fill:#ff0"
          d="m33.9 4.52-12.24.23L6.23 60.09l21.01.48-23.29 58.36 31.26-63.9-18.63-3.18L33.9 4.52z"
        /></svg
      >
      <span>nsjakk</span>
    </div>
    {#if !$hasJoinedLobby}
      <Button on:click={() => (joinLobbyModalOpen = true)}>Nytt parti</Button>
    {:else}
      <Button kind="danger-tertiary" on:click={leaveLobby}>Avbryt søk</Button>
    {/if}
  </div>
  <DataTable
    headers={[
      { key: "username", value: "Navn" },
      { key: "time", value: "Tid i minutter" },
    ]}
    rows={$lobby.map((row) => ({
      ...row,
      time: `${row.time / 60}`, //+ ${row.time === 300 ? 3 : 2},
    }))}
    on:click:row={({ detail }) => {
      if (detail?.id !== $UID) {
        joinGame({ id: detail.id, time: detail.time * 60 });
      }
    }}
  />
  {#if $loadingLobby}
    <DataTableSkeleton
      headers={["", ""]}
      showHeader={false}
      showToolbar={false}
    />
  {/if}
  {#if isEmpty}
    <div class="empty-lobby-text" in:fade>Ingen andre søker parti nå</div>
  {/if}
</div>
<JoinLobbyModal
  open={joinLobbyModalOpen}
  on:click:button--secondary={closeJoinLobbyModal}
  on:close={closeJoinLobbyModal}
  on:submit={onSubmit}
/>

<style>
  .lobby {
    display: flex;
    flex-direction: column;
  }
  @media screen and (min-width: 670px) {
    .lobby {
      padding-top: 24px;
    }
  }
  .lobby-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    padding: 0 14px;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
  }
  .title svg {
    margin-bottom: -18px;
    padding: 0 2px;
  }
  .lobby-header svg {
    height: 1.75rem;
  }
  :global(.empty table) {
    display: none !important;
  }
  .empty-lobby-text {
    opacity: 0.8;
    font-size: 1.2rem;
    padding: 80px 14px;
    max-width: 30ch;
    text-align: center;
    font-style: italic;
    margin: auto;
  }
  :global(.bx--data-table-header__title) {
    font-size: 2rem !important;
  }
  :global(.bx--data-table-header__description) {
    font-size: 1.25rem !important;
  }
  :global(.bx--skeleton) {
    padding-top: 0;
    margin-top: -24px;
  }
  :global(tbody:hover) {
    cursor: pointer;
  }
  :global(.myRow td) {
    pointer-events: none;
    cursor: default;
  }
</style>
